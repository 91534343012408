import React, { useEffect, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import isThisYear from 'date-fns/isYesterday';
import parseISO from 'date-fns/parseISO';
import { RootState, Dispatch } from 'store';
import styled from '@emotion/styled';

import Chat from 'components/Chat';
import Message from 'components/Chat/Message';
import { scrollToBottom } from 'components/Chat/helper';
import unreadTitlebar from '../../../helpers/unreadTitlebar';

const META_TIME_FORMAT = 'hh:mm aaa';
const DATE_FORMAT = 'MMM dd, yyyy';

const Container = styled.div`
  flex: 1;
  overflow: auto;
`;

const mapState = (state: RootState): any => ({
  messages: state.chat.messages,
  currentMemberId: state.app.memberId,
  currentCrmId: state.app.user.crmId,
  showChat: state.chat.show,
  isLoadingMessages: state.loading.effects.chat.getMessages,
  isChatInitialized: state.chat.isInitialized,
  token: state.app.token,
});

const mapDispatch = (dispatch: Dispatch): any => ({
  getMessages: dispatch.chat.getMessages,
  setUnreadNum: dispatch.chat.setUnreadNum,
});

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;
function Content({
  messages,
  currentMemberId,
  currentCrmId,
  setUnreadNum,
  token,
}: ReduxProps) {
  useEffect(() => {
    if (messages.length === 0) return;

    const key = `lst-read-ts-${token}`;
    const lastItem = messages[messages.length - 1];

    localStorage.setItem(key, lastItem.id);
    setUnreadNum(0);
    unreadTitlebar(0);
    scrollToBottom();
  }, [setUnreadNum, messages, token]);

  return (
    <Container>
      <Chat className="slds-p-around_small  slds-scrollable_y">
        {messages.length > 0 ? (
          <>
            {messages.map((message: any, index: number) => {
              const {
                memberDisplayName,
                id,
                memberId,
                crmId,
                body,
                createdDate,
              } = message;

              const messageDate =
                createdDate instanceof Date
                  ? createdDate
                  : parseISO(createdDate);
              const prevMessageDate =
                messages[index - 1]?.createdDate instanceof Date
                  ? messages[index - 1]?.createdDate
                  : parseISO(messages[index - 1]?.createdDate);

              // Event
              const isPrevMessageDiffDate =
                index > 0 && !isSameDay(messageDate, prevMessageDate);

              let dateEvent = null;
              if (isToday(messageDate)) {
                dateEvent = 'Today';
              } else if (isYesterday(messageDate)) {
                dateEvent = 'Yesterday';
              } else {
                let dateFormat = 'EEE dd, yyyy';
                if (isThisYear(messageDate)) {
                  dateFormat = 'ddd, EEE dd';
                }

                dateEvent = format(new Date(createdDate), dateFormat);
              }

              const dateEventString = format(
                new Date(createdDate),
                DATE_FORMAT
              );
              const DateToday =
                index === 0 || isPrevMessageDiffDate ? (
                  <Message type="event">
                    <label
                      className="slds-text-title_caps"
                      title={dateEventString}
                    >
                      {dateEvent}
                    </label>
                  </Message>
                ) : null;

              // Inbound & Outbound
              const msgDate = format(
                new Date(message.createdDate),
                META_TIME_FORMAT
              );

              const isCurrentUserMessage =
                currentMemberId === memberId || currentCrmId === crmId;

              let meta = [memberDisplayName || memberId, msgDate]
                .filter((s) => s)
                .join(' • ');

              const metaAriaLabel = meta;

              const isNextMessageSameUser =
                index !== messages.length - 1 &&
                (messages[index + 1].memberId === memberId ||
                  messages[index + 1].crmId === crmId);

              if (isNextMessageSameUser) {
                meta = '';
              }
              const bodyTitle = format(
                new Date(createdDate),
                `MMM dd, yyyy ${META_TIME_FORMAT}`
              );
              return (
                <Fragment key={id}>
                  {DateToday}
                  <Message
                    key={id}
                    id={id.toString()}
                    type={isCurrentUserMessage ? 'outbound' : 'inbound'}
                    meta={meta}
                    metaAriaLabel={metaAriaLabel}
                    className={messages.length === 1 ? 'animated bounceIn' : ''}
                  >
                    <label title={bodyTitle}> {body}</label>
                  </Message>
                </Fragment>
              );
            })}
          </>
        ) : (
          <div className="slds-align_absolute-center">No Messages</div>
        )}
      </Chat>
    </Container>
  );
}

export default connector(Content);
