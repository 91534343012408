import React, { useState } from 'react';
import Button from '@salesforce/design-system-react/lib/components/button';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import cx from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';

import ToastyManager from 'containers/Toasty/ToastyManager';
import api from 'helpers/api';

import styles from './style.module.css';

interface IProps {
  twoFactorMessage: string;
  roomId: string;
  orgId: string;
  sessionId: string;
  getSite: Function;
  appUpdate: Function;
}

function TwoFactor({
  twoFactorMessage,
  roomId,
  orgId,
  sessionId,
  getSite,
  appUpdate,
}: IProps) {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [ident, setIdent] = useState(0);

  const inputAttributes = hasError
    ? {
        'aria-describedby': 'error-message-required',
      }
    : {};

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value);

    if (hasError) {
      setHasError(false);
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    if (code.trim() === '') {
      setHasError(true);
      return;
    }

    try {
      setIsLoading(true);
      await api.post(
        `/rooms/${roomId}/passcode`,
        {
          origin: 'web',
          orgId,
          sessionId,
          passcode: code,
        },
        true // Suppress toast since we would want to do it manually
      );

      if (ident) {
        setIdent(0);

        ToastyManager.emit({
          type: 'delete-toast',
          ident,
        });
      }

      await getSite(roomId);
      appUpdate({ key: 'showTwoFactor', value: false });
    } catch (error) {
      const _ident = Date.now();
      setIdent(_ident);

      ToastyManager.emit({
        variant: 'error',
        type: 'toast',
        message: get(error, ['response', 'data', 'message'], error.message),
        ident: _ident,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleResend(event: any) {
    event.preventDefault();

    try {
      setIsLoading(true);

      await api.post(`/rooms/${roomId}/passcode`, {
        origin: 'web',
        orgId,
        sessionId,
        resend: true,
      });
    } catch (error) {
      // Do nothing
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.floatingWrapper}>
      <div className="slds-text-heading_large slds-text-align_center slds-m-bottom_small">
        Two-step verification
      </div>
      <div className={styles.floatingContainer}>
        {isLoading && <Spinner size="x-small" />}
        <p className="slds-m-bottom_x-small slds-text-body_regular">
          {twoFactorMessage}
        </p>
        <div className="slds-m-bottom_x-small">
          <form
            className={cx('slds-form-element', hasError && 'slds-has-error')}
            onSubmit={handleSubmit}
          >
            <label className="slds-form-element__label" htmlFor="token">
              <abbr className="slds-required" title="required">
                *&nbsp;
              </abbr>
              Please enter code below to access
            </label>
            <div className="slds-form-element__control">
              <input
                type="text"
                id="token"
                placeholder="5-digit code"
                className="slds-input"
                name="token"
                inputMode="numeric"
                onChange={handleChange}
                value={code}
                autoComplete="one-time-code"
                required
                {...inputAttributes}
              />
            </div>
            {hasError && (
              <div
                className="slds-form-element__help"
                id="error-message-required"
              >
                This field is required
              </div>
            )}
          </form>
        </div>
        <p className="slds-grid">
          <Button
            label="Continue"
            variant="brand"
            className="slds-col"
            onClick={handleSubmit}
          />
        </p>
        <p className="">
          Didn't receive the code?
          <Button
            className="slds-m-left_xx-small"
            label="Resend"
            variant="base"
            onClick={handleResend}
          />
        </p>
        <p className="slds-m-bottom_x-small" hidden>
          Incorrect phone number?
          <Button
            className="slds-m-left_xx-small"
            label="Contact us"
            variant="base"
          />
        </p>
      </div>
    </div>
  );
}

const mapState = (state: any) => ({
  twoFactorMessage: state.app.twoFactorMessage,
  roomId: state.app.roomId,
  orgId: state.app.orgId,
  sessionId: state.app.sessionId,
});

const mapDispatch = (dispatch: any) => ({
  appUpdate: dispatch.app.update,
  getSite: dispatch.site.getSite,
});

export default connect(mapState, mapDispatch)(TwoFactor);
