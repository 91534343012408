var styles = {
  outerContainer: {
    minHeight: '100%',
    display: 'flex'
  },
  container: {
    padding: 0,
    flex: '1'
  },
  previewContainer: {
    flex: 1,
    minHeight: 'calc(100vh - 7.8rem)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& header': {
      background: 'white',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      padding: ['1em', '2em'],
      marginBottom: '3em'
    },
    '& h1 span': {
      whiteSpace: 'nowrap'
    }
  },
  contents: {
    minHeight: '50%',
    flexWrap: 'wrap',
    flex: 1,
    '& .smooth-dnd-container.vertical': {
      '& > .smooth-dnd-draggable-wrapper': {
        overflow: 'visible'
      }
    }
  },
  bannerContainer: {
    display: 'flex'
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    justifyContent: 'center',
    flex: 1,
    '& ul': {
      margin: 0
    },
    '& li': {
      display: 'inline-block',
      padding: '0 2rem'
    },
    '& a.active': {
      color: '#16325c',
      fontWeight: 'bold'
    }
  },
  hamburger: {
    display: 'none'
  },
  show: {
    display: 'block'
  },
  logo: {
    height: 35,
    marginRight: 10,
    verticalAlign: 'bottom'
  },
  mobile: {
    flex: 'none',
    width: 430,
    height: 729,
    margin: '3rem auto 0',
    padding: '77px 23px',
    position: 'relative',
    borderRadius: 30,
    border: '0.5px solid #e0e0e0',
    backgroundColor: 'white',
    boxShadow: 'inset 0px 0px 0px -4px rgba(255, 255, 255, 0.1), 1px 1px 6px rgba(0, 0, 0, 0.05), 1px 1px 8px rgba(0, 0, 0, 0.07)',
    '&::after': {
      boxShadow: '0 0 0 1px #c9c7c5',
      border: 0,
      content: '""',
      position: 'absolute',
      pointerEvents: 'none',
      top: 77,
      bottom: 77,
      left: 23,
      right: 23
    },
    '& $previewContainer': {
      maxHeight: '100%',
      minHeight: '100%',
      overflow: 'auto',
      '& .slds-size_1-of-1': {
        width: '100%'
      },
      '& header': {
        display: 'flex',
        padding: 25
      }
    },
    '& $contents': {
      minHeight: '0'
    },
    '& $navContainer': {
      background: 'white',
      boxShadow: '0 5px 5px 1px #aaa',
      top: 0,
      left: 0,
      position: 'absolute',
      paddingTop: 35,
      width: '100%',
      zIndex: 7990,
      textAlign: 'center',
      transition: 'all 0.5s ease',
      '&:not($show)': {
        overflow: 'hidden',
        top: '-200%'
      },
      '& li': {
        width: '100%'
      },
      '& li a': {
        display: 'block',
        padding: 20,
        width: '100%'
      }
    },
    '& $hamburger': {
      position: 'absolute',
      display: 'inline-block',
      marginRight: 20,
      zIndex: 7991
    },
    '& $bannerContainer': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 40,
      textAlign: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    '& $logo': {
      height: 35,
      marginRight: 10,
      verticalAlign: 'middle'
    },
    '& $bannerHeader': {
      fontSize: '1.5rem'
    }
  },
  '@media (max-width: 64em)': {
    container: {
      padding: 0
    },
    mobile: {
      padding: '77px 23px'
    },
    bannerHeader: {
      fontSize: '1.5rem'
    },
    previewContainer: {
      maxHeight: '100%',
      overflow: 'auto',
      '& .slds-size_1-of-1': {
        width: '100%'
      },
      '& header': {
        display: 'flex',
        padding: 25
      }
    },
    contents: {
      minHeight: '0'
    },
    show: {
      display: 'block'
    },
    navContainer: {
      background: 'white',
      boxShadow: '0 5px 5px 1px #aaa',
      top: 0,
      left: 0,
      position: 'absolute',
      paddingTop: 35,
      width: '100%',
      zIndex: 7990,
      textAlign: 'center',
      transition: 'all 0.5s ease',
      '&:not($show)': {
        overflow: 'hidden',
        top: '-200%'
      },
      '& li': {
        width: '100%'
      },
      '& li a': {
        display: 'block',
        padding: 20,
        width: '100%'
      }
    },
    hamburger: {
      position: 'absolute',
      display: 'inline-block',
      marginRight: 20,
      marginTop: 5,
      zIndex: 7991
    },
    bannerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 40,
      textAlign: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    logo: {
      height: 35,
      marginRight: 10,
      verticalAlign: 'middle'
    }
  }
};
export default styles;