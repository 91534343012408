import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState, Dispatch } from 'store';
import { Message } from './types';
import Launcher from './components/Launcher';
import ChatBox from './components/ChatBox';
import MessageBubble from './components/DefaultMessageBubble';
import setTitlebar from 'helpers/unreadTitlebar';

interface IStateProps {
  isChatInitialized: boolean;
  messages: Message[];
  unread: number;
  defaultRoomMessage: string;
}

interface IDispatchProps {
  getMessages: () => void;
}

const mapState = (state: RootState): IStateProps => ({
  isChatInitialized: state.chat.isInitialized,
  messages: state.chat.messages,
  unread: state.chat.unread,
  defaultRoomMessage: state.site.defaultRoomMessage,
});

const mapDispatch = (dispatch: Dispatch): IDispatchProps => ({
  getMessages: dispatch.chat.getMessages,
});

function ChatRoom({
  defaultRoomMessage,
  messages,
  getMessages,
  isChatInitialized,
  unread,
}: IStateProps & IDispatchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  useEffect(() => {
    let timeout: number;

    if (
      unread !== 1 ||
      messages.length !== 1 ||
      messages[0].body !== defaultRoomMessage
    ) {
      setUnreadCount(messages.length);
      return;
    }

    timeout = window.setTimeout(() => {
      setPopupMessage(defaultRoomMessage);
      setUnreadCount(unread);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [unread, messages, defaultRoomMessage]);

  useEffect(() => {
    setTitlebar(unread);
  }, [unread]);

  useEffect(() => {
    if (
      !isLoaded &&
      isChatInitialized &&
      ((messages.length === 1 && messages[0].body !== defaultRoomMessage) ||
        messages.length > 1)
    ) {
      // setIsOpen(true);
      setIsLoaded(true);
    }
  }, [isChatInitialized, messages, isLoaded, defaultRoomMessage]);

  if (!isChatInitialized) {
    return null;
  }

  return (
    <>
      {isOpen ? <ChatBox /> : null}
      {popupMessage ? (
        <MessageBubble
          message={defaultRoomMessage}
          onClick={() => {
            setIsOpen(true);
            setPopupMessage('');
          }}
        />
      ) : null}
      <Launcher
        unreadCount={unreadCount}
        onClick={() => {
          setIsOpen(!isOpen);
          setPopupMessage('');
        }}
        isOpen={isOpen}
      />
    </>
  );
}

export default connect(
  mapState,
  (mapDispatch as unknown) as IDispatchProps // Hack... Can't figure out the proper way to do this using redux rematch
)(ChatRoom);
