import React from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

type Props = {
  message: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const slideLeft = keyframes`
  from {
    right: 5rem;
  }
  
  to {
  right: 5.5rem;
  }
`;

const Container = styled.div`
  animation: ${slideLeft} 0.5s ease;
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 5.5rem;
  z-index: 99;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  font-size: 1.1rem;
  padding: 1rem;
  max-width: 270px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
`;

export default function MessageBubble({ message, onClick }: Props) {
  return createPortal(
    <Container className="slds-chat-message__text_inbound" onClick={onClick}>
      {message}
    </Container>,
    document.getElementById('root') || document.body
  );
}
