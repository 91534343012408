/* eslint-disable no-control-regex, no-useless-escape */
/** @jsx jsx */
import { useState } from 'react';
import Button from '@salesforce/design-system-react/lib/components/button';
import { css, jsx } from '@emotion/core';

import { ToastyManager } from 'containers/Toasty';
import SFieldState, { FieldWithFlags, ErrorStack } from 'states/SFieldState';
import api from 'helpers/api';

type Props = {
  data: any;
  pageId: string;
  sobjectType: string;
  masterRecordId: string;
  orgId: string;
  roomId: string;
  setPage: (page: string) => void;
};

// These regexs are from yup's source code. Apparantly, it's not a simple regex for these stuff :(
let rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
let rUrl = /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export default function SFieldSubmit({
  data,
  pageId,
  orgId,
  roomId,
  masterRecordId,
  sobjectType,
  setPage,
}: Props) {
  const [isSending, setIsSending] = useState(false);
  const { getState, setErrors, hideForm } = SFieldState.useContainer();
  const initialFields = getState(pageId) as FieldWithFlags[];
  const {
    displayLabel,
    displayType,
    displayColor,
    displayFontColor,
    displayWidth,
    displayHeight,
  } = data;

  if (initialFields.some((field) => field.hidden)) {
    return null;
  }

  async function handleClick() {
    const fields = getState(pageId);

    const requiredButNoValue = (fields as FieldWithFlags[]).reduce(
      (stack: ErrorStack[], field: FieldWithFlags, index: number) => {
        if (field.required && (field.value || '').trim() === '') {
          return [...stack, { index, message: 'This field is required' }];
        }

        return stack;
      },
      []
    );

    const invalidPatternFields = fields.reduce(
      (stack: ErrorStack[], field: FieldWithFlags, index: number) => {
        if (
          field.type === 'email' &&
          field.value &&
          !rEmail.test(field.value)
        ) {
          return [...stack, { index, message: 'Invalid Email' }];
        } else if (
          field.type === 'url' &&
          field.value &&
          !rUrl.test(field.value || '')
        ) {
          return [...stack, { index, message: 'Invalid URL' }];
        }

        return stack;
      },
      []
    );

    if (requiredButNoValue.length > 0 || invalidPatternFields.length > 0) {
      setErrors(pageId, [...requiredButNoValue, ...invalidPatternFields]);
      return;
    }

    try {
      setIsSending(true);
      await api.post(`/rooms/${roomId}/forms`, {
        orgid: orgId,
        roomid: roomId,
        id: masterRecordId,
        sObjectType: sobjectType,
        fields,
      });
      setIsSending(false);

      hideForm(pageId);

      switch (data.nextAction) {
        case 'URL':
          if (data.nextPage) {
            window.location.href = data.nextPage;
          }
          break;

        case 'PAGE':
          if (data.nextPage) {
            setPage(data.nextPage);
          }
          break;

        case 'MESSAGE':
        default:
          ToastyManager.emit({
            type: 'toast',
            variant: 'success',
            message: data.nextPage || 'Form submitted!',
          });
          break;
      }
    } catch (error) {
      console.error(error);
      setIsSending(false);
    }
  }

  const backgroundColor =
    displayType === 'BUTTON' ? displayColor : 'transparent';
  const color =
    displayType === 'BUTTON' ? displayFontColor || '#FFF' : '#0070d2';

  return (
    <Button
      label={isSending ? 'Sending...' : displayLabel}
      variant={displayType === 'BUTTON' ? 'neutral' : 'link'}
      className="slds-m-vertical_small slds-truncate"
      css={css`
        margin: 1em;
        height: ${Number(displayHeight || 32) / 16}rem;

        @media (hover: none) and (pointer: coarse) {
          height: ${Number(displayHeight || 32) / 13}rem;
          line-height: ${Number(displayHeight || 32) / 13}rem;
        }
      `}
      style={{
        backgroundColor: isSending ? '#c9c7c5' : backgroundColor,
        color: isSending ? '#dddbda' : color,
        minWidth: displayWidth ? 0 : 100,
        width: displayWidth ? Number(displayWidth) : 'auto',
      }}
      onClick={handleClick}
      disabled={isSending}
    />
  );
}
