/** @jsx jsx */
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { css, jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { ReactComponent as Logo } from '../images/idialogue-icon.svg';
import { ReactComponent as BlankLogo } from '../images/idialogue-icon-blank.svg';

type AnimationProps = {
  isOpen: boolean;
};

type Props = {
  unreadCount: number;
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
};

const slideUp = keyframes`
  from {
    transform: translateY(1em);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const leftWing = keyframes`
  from {
    transform: translate(0, 0) rotate(0deg) scaleY(1);
  }

  to {
    transform: translate(8px, 0px) rotate(90deg) scaleY(0.5) scaleX(0.5) skewX(-50deg);
  }
`;

const rightWing = keyframes`
  from {
    transform: translate(8px, 0px) rotate(90deg) scaleY(0.5) scaleX(0.5) skewX(-50deg);
  }

  to {
    transform: translate(21px,0px) rotate(90deg) skewX(50deg) scaleX(0.5) scaleY(0.5);
  }
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.7);
  }
  
  50% {
  transform: scale(0.5);
  }
  
  to {
  transform: scale(1);
  }
`;

const Container = styled.div`
  animation: ${slideUp} 1s ease;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  bottom: 1rem;
  right: 1rem;
  transform: translateY(0);
  opacity: 1;
  width: 60px;
  height: 50px;
`;

const UnreadCount = styled.div`
  align-items: center;
  background: rgb(185, 51, 49);
  border-radius: 10em;
  box-shadow: 0 0 3px 3px white;
  color: white;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -1.25em;
  right: -0.5em;
  font-size: 0.9em;
  width: 2em;
  height: 2em;
  animation: ${scaleUp} 0.5s ease;
`;

const CollapseLauncherContainer = styled.div<AnimationProps>`
  width: 60px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.25s ease;

  ${({ isOpen }: AnimationProps) => {
    return isOpen
      ? css`
          opacity: 1;
        `
      : '';
  }}
`;

const logoStyle = css`
  width: 60px;
  height: 50px;
  position: absolute;
`;

function CollapseLauncher({ isOpen }: AnimationProps) {
  const [isFirstDone, setIsFirstDone] = useState(false);

  return (
    <CollapseLauncherContainer
      isOpen={isOpen}
      onTransitionEnd={() => setIsFirstDone(!isFirstDone && isOpen)}
    >
      <div
        css={css`
          background: white;
          width: 7.77px;
          left: 7.77px;
          position: absolute;
          height: 26px;
          top: 10px;
          z-index: 1;

          ${isFirstDone
            ? css`
                animation: ${leftWing} 0.75s ease;
                transform: translate(8px, 0px) rotate(90deg) scaleY(0.5)
                  scaleX(0.5) skewX(-50deg);
              `
            : ''}
        `}
      />
      <div
        css={css`
          background: white;
          width: 7.77px;
          left: 7.77px;
          position: absolute;
          height: 26px;
          top: 10px;
          z-index: 1;

          ${isFirstDone
            ? css`
                animation: ${rightWing} 0.75s ease;
                transform: translate(21px, 0px) rotate(90deg) skewX(50deg)
                  scaleX(0.5) scaleY(0.5);
              `
            : ''}
        `}
      />
      <BlankLogo css={logoStyle} />
    </CollapseLauncherContainer>
  );
}

export default function Launcher({
  unreadCount = 0,
  isOpen,
  onClick,
}: Props & AnimationProps) {
  return createPortal(
    <Container id="idlog-chat" onClick={onClick}>
      <Logo css={logoStyle} />
      <CollapseLauncher isOpen={isOpen} />
      {unreadCount > 0 ? <UnreadCount>{unreadCount}</UnreadCount> : null}
    </Container>,
    document.getElementById('root') || document.body
  );
}
