import React from 'react';

import FileUpload from './FileUpload';

import styles from './FileUploadContainer.module.scss';

interface Props {
  title: string;
  multiFileEnabled: boolean;
  supportedFileTypes: string[];
}

function FileUploadContainer({
  title,
  supportedFileTypes,
  multiFileEnabled,
  ...props
}: Props) {
  return (
    <div className={styles.container}>
      <FileUpload
        variant="file"
        label="File"
        buttonLabel={title}
        multiple={multiFileEnabled}
        allowedTypes={supportedFileTypes}
        {...props}
      />
    </div>
  );
}

export default FileUploadContainer;
