export default [
  {
    id: 'Dawning of a New Day',
    label: 'Cursive 1',
  },
  {
    id: 'Debby',
    label: 'Cursive 2',
  },
  {
    id: 'Flanella',
    label: 'Cursive 3',
  },
  {
    id: 'Good Vibes',
    label: 'Cursive 4',
  },
  {
    id: 'Variane Scripts',
    label: 'Cursive 5',
  },
];
