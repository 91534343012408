import React, { useState, useRef, useEffect } from 'react';
import Button from '@salesforce/design-system-react/lib/components/button';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Tabs from '@salesforce/design-system-react/lib/components/tabs';
import TabsPanel from '@salesforce/design-system-react/lib/components/tabs/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import SignatureCanvas from 'react-signature-canvas';
import * as htmlToImage from 'html-to-image';

import fonts from './constants/fonts';

import styles from './Document.module.scss';

type Props = {
  terms: string;
  user: any;
  onRequestClose: () => void;
  onRequestSave: (image: string) => void;
  isOpen: boolean;
  isLoading: boolean;
  enableFreeFormSignatures: boolean;
  enableAdoptedSignatures: boolean;
};

export default function ESign({
  terms,
  user,
  onRequestClose,
  onRequestSave,
  isOpen,
  isLoading,
  enableFreeFormSignatures,
  enableAdoptedSignatures,
}: Props) {
  const [step, setStep] = useState(0);
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isConverting, setIsConverting] = useState(false);
  const canvasRef = useRef(null);
  const sigTextAreaRef = useRef(null);
  const defaultText = [user.firstName, user.lastName].join(' ');

  useEffect(() => {
    setStep(0);
  }, [isOpen]);

  async function handleSave() {
    if (selectedTab === 0) {
      if (canvasRef && canvasRef.current) {
        const base64Image = ((canvasRef.current as unknown) as any).toDataURL(
          'image/png'
        );
        onRequestSave(base64Image);

        return;
      }
    } else if (selectedTab === 1) {
      if (sigTextAreaRef && sigTextAreaRef.current) {
        setIsConverting(true);
        const canvas = await htmlToImage.toCanvas(
          (sigTextAreaRef.current as unknown) as any
        );

        const base64Image = canvas.toDataURL('image/png');

        setIsConverting(false);
        onRequestSave(base64Image);

        return;
      }
    }

    onRequestSave('');
  }

  function handleClearCanvas() {
    if (canvasRef && canvasRef.current) {
      ((canvasRef.current as unknown) as any).clear();
    }
  }

  function handleFontChange(_: any, data: any) {
    if (data.selection && data.selection && data.selection[0]) {
      setSelectedFont(data.selection[0]);
    }
  }

  function handleTabChange(index: number) {
    setSelectedTab(index);
  }

  function handleAgreeCheck() {
    setStep((state) => state + 1);
  }

  const renderTerms = () => {
    return (
      <div className="slds-p-around_large">
        <p className="slds-m-bottom_medium">
          <Checkbox
            assistiveText={{
              label: 'I agree to use electronic records and signatures',
            }}
            labels={{
              label: 'I agree to use electronic records and signatures',
            }}
            onChange={handleAgreeCheck}
          />
        </p>
        <p>{terms}</p>
      </div>
    );
  };

  const renderESignForm = () => {
    return (
      <Tabs className={styles.esignTabsContainer} onSelect={handleTabChange}>
        {enableFreeFormSignatures ? (
          <TabsPanel label="Draw">
            <div className={styles.sigPadOuterContainer}>
              <div className={styles.sigPadContainer}>
                <div className={styles.sigPadBackground}>
                  <span className={styles.sigPadSignHere}>
                    Please Sign Here
                  </span>
                  <div className={styles.sigPadMessage}>
                    <p className={styles.sigPadUnderlineContainer}>
                      <span>X</span>
                      <span className={styles.sigPadUnderline} />
                    </p>
                  </div>
                </div>
                <SignatureCanvas
                  penColor="black"
                  minWidth={1}
                  maxWidth={4}
                  canvasProps={{ className: styles.sigPad }}
                  ref={canvasRef}
                />
                <Button
                  label="Clear"
                  className={styles.canvasClearButton}
                  onClick={handleClearCanvas}
                  variant="base"
                />
              </div>
            </div>
          </TabsPanel>
        ) : null}
        {enableAdoptedSignatures ? (
          <TabsPanel label="Type">
            <div className={styles.signatureTextContainer}>
              <Combobox
                className="slds-m-bottom_small"
                labels={{ label: 'Font' }}
                selection={[selectedFont]}
                options={fonts}
                variant="readonly"
                events={{
                  onSelect: handleFontChange,
                }}
              />
              <div className={styles.signatureTextAreaBorder}>
                <div
                  ref={sigTextAreaRef}
                  className={styles.signatureTextArea}
                  style={{ fontFamily: selectedFont.id }}
                  contentEditable
                >
                  {defaultText}
                </div>
              </div>
            </div>
          </TabsPanel>
        ) : null}
      </Tabs>
    );
  };

  return (
    <Modal
      dismissOnClickOutside={false}
      onRequestClose={onRequestClose}
      size="small"
      containerClassName={styles.eSignModal}
      contentClassName={styles.eSignModalContent}
      footer={
        step === 1 ? (
          <div className={styles.esignFooter}>
            <Button
              label="Cancel"
              disabled={isLoading || isConverting}
              onClick={onRequestClose}
            />
            <Button
              label="Sign"
              variant="brand"
              disabled={isLoading || isConverting}
              onClick={handleSave}
            />
          </div>
        ) : undefined
      }
      isOpen={isOpen}
    >
      {step === 0 ? renderTerms() : null}
      {step === 1 ? renderESignForm() : null}
    </Modal>
  );
}
