/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { Formik } from 'formik';

import Textarea from '@salesforce/design-system-react/lib/components/textarea';
import Button from '@salesforce/design-system-react/lib/components/button';

import { scrollToBottom } from 'components/Chat/helper';

interface FormValues {
  message: string;
}

const mapState = (state: RootState): any => ({
  showChat: state.chat.show,
});

const mapDispatch = (dispatch: Dispatch): any => ({
  getMessages: dispatch.chat.getMessages,
  sendMessage: dispatch.chat.sendMessage,
});

const ChatForm = ({ sendMessage, showChat }: any) => {
  const initialValues = { message: '' };

  const onSubmit = ({ message }: FormValues, { resetForm }: any) => {
    if (message.trim() === '') {
      resetForm();
      return;
    }
    sendMessage(message);
    resetForm();
    scrollToBottom();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props: any) => {
        const onKeyUp = (e: any) => {
          if (e.keyCode === 13) {
            onSubmit(props.values, props);
          }
        };
        return (
          <form
            className={`slds-grid chat-room-form slds-p-horizontal_small slds-p-bottom_small slds-p-bottom_x-small`}
            onSubmit={props.handleSubmit}
          >
            <div className="slds-col slds-is-relative">
              <Textarea
                className="slds-textarea slds-p-right_xx-large"
                name="message"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onKeyUp={onKeyUp}
                placeholder="Type a message"
                value={props.values.message}
                autoFocus
              />

              <Button
                className="slds-is-absolute"
                type="submit"
                disabled={props.values.message.length === 0}
                assistiveText={{ icon: 'Send' }}
                iconCategory="utility"
                iconName="send"
                iconSize="large"
                variant="icon"
                css={css`
                  right: 1em;
                  top: 1em;
                `}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

export default connect(mapState, mapDispatch)(ChatForm);
