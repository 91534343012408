import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import cx from 'classnames';
import Icon from '@salesforce/design-system-react/lib/components/icon';

import { Plugin } from 'containers/Site/types';
import * as PluginsTypes from 'plugins';
import { HandleUploadObject } from 'plugins/FileUpload/types';
import CheckMark from './CheckMark';

import api from 'helpers/api';
import { Dispatch, RootState } from 'store';

import styles from './Checklist.module.scss';

interface DispatchProps {
  fileUpload?: (a: any) => any;
  fileDelete?: (a: any) => any;
  actionClick?: (a: any) => any;
}

interface StateProps {
  userRole?: number;
  deleteAccess?: number;
  supportedFileTypes?: string[];
  orgId?: string;
  roomId?: string;
  sessionId?: string;
  user?: any;
}

interface PluginsProps {
  plugin: any;
  pageId: string;
}

const mapState = (state: RootState): any => ({
  userRole: state.app.role,
  deleteAccess: state.site.itemDeleteAccess,
  supportedFileTypes: state.site.supportedFileTypes,
  orgId: state.app.orgId,
  roomId: state.app.roomId,
  sessionId: state.app.sessionId,
  user: state.app.user,
});

const mapDispatch = (dispatch: Dispatch): any => ({
  actionClick: dispatch.site.actionClick,
  fileUpload: dispatch.site.fileUpload,
  fileDelete: dispatch.site.fileDelete,
});

function Plugins(props: PluginsProps & StateProps & DispatchProps) {
  const handleUpload = useCallback(
    ({ data, id }: HandleUploadObject) => {
      return new Promise(async (resolve) => {
        const { fileUpload, pageId } = props;
        if (fileUpload) {
          await fileUpload({
            id,
            pageId,
            data,
          });

          resolve();
        } else {
          resolve();
        }
      });
    },
    [props.pageId]
  );

  const handleDocumentAction = useCallback(
    ({ type, id, url, data }: any): void => {
      switch (type) {
        case 'download':
          window.open(url, 'idlog-download');
          break;

        case 'delete':
          if (typeof props.fileDelete === 'function') {
            return props.fileDelete(id);
          }
          break;

        case 'generic-action':
          if (typeof props.actionClick === 'function') {
            return props.actionClick({
              id,
              data: { ...data, pageId: props.pageId },
            });
          }
          break;
      }
    },
    [props.pageId]
  );

  const handleDocumentClick = useCallback(async ({ endpoint, id }: any) => {
    try {
      const response = await api.post(endpoint, {
        orgId: props.orgId,
        roomId: props.roomId,
        itemId: id,
        downloadItem: true,
      });

      window.open(response.item.signedURL, 'idlog-download');
    } catch (error) {
      console.error(error);
    }
  }, []);

  const plugins = props.plugin.children || [];
  const render = plugins.map((plugin: Plugin) => {
    const Component = (PluginsTypes as any)[plugin.type];
    const id = plugin.id || plugin.clientTimestamp;

    const uniqueProps: any = {};

    switch (plugin.type) {
      case 'Upload': {
        const { supportedFileTypes } = props;
        uniqueProps.onUpload = handleUpload;
        uniqueProps.supportedFileTypes = supportedFileTypes;
        uniqueProps.multiFileEnabled = plugin.multiFileEnabled;
        break;
      }

      case 'Document': {
        const { userRole, deleteAccess } = props;
        uniqueProps.onClick = handleDocumentClick;
        uniqueProps.onActionClick = handleDocumentAction;
        uniqueProps.actions = plugin.actions || [];
        uniqueProps.canDelete =
          userRole !== undefined &&
          deleteAccess !== undefined &&
          userRole >= deleteAccess &&
          deleteAccess !== 0;
        break;
      }

      case 'Payment': {
        uniqueProps.orgId = props.orgId;
        uniqueProps.roomId = props.roomId;
        uniqueProps.user = props.user;
        break;
      }
    }

    return (
      <div
        className={styles.pluginContainer}
        key={`${plugin.name}-${id}-checklist-child`}
      >
        {plugin.type !== 'Text' && <CheckMark checked={plugin.checked} />}
        <div className={styles.pluginComponentContainer}>
          <Component
            id={`${plugin.name}-${id}-checklist-child`}
            value={plugin.content}
            plugin={omit(plugin, ['contentDocument', 'item'])}
            extraData={{ ...plugin.contentDocument, ...plugin.item }}
            title={plugin.title}
            {...uniqueProps}
          />
        </div>
      </div>
    );
  });

  return (
    <div className={cx('slds-p-around_large', styles.container)}>{render}</div>
  );
}

export default connect(mapState, mapDispatch)(Plugins);
