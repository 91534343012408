import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import ReactGA from 'react-ga';

import Resend from './Resend';
import TwoFactor from './TwoFactor';
import Chat from '../Chat';

import { Dispatch, RootState } from 'store';

import Toasty from 'containers/Toasty';
import Site from 'containers/Site';

const GOOGLE_ANALYTICS_ID = 'UA-178244431-1';

const mapState = (state: RootState) => ({
  siteId: state.site.id,
  siteOptions: state.site.options,
  messagingAccess: state.site.messagingAccess,
  isLoading: state.loading.effects.site.getSite,
  isInitalized: state.app.isInitialized,
  isChatVisible: state.chat.show,
  showResendToken: state.app.showResendToken,
  showTwoFactor: state.app.showTwoFactor,
});

const mapDispatch = (dispatch: Dispatch) => ({
  redeem: dispatch.app.redeem,
});

function extractQuery(str: string) {
  return str.split('&').reduce((stack, seg) => {
    const [key, value] = seg.split('=');
    return {
      ...stack,
      [key]: value,
    };
  }, {} as Record<string, string>)
}

const App: React.FC = ({
  isLoading,
  isInitalized,
  siteId,
  siteOptions = {},
  redeem,
  messagingAccess,
  showResendToken,
  showTwoFactor,
}: any) => {
  const [gaInit, setGaInit] = useState(false);
  useEffect(() => {
    // Get query string from location
    const { search } = window.location;
    const queryString = search.replace(/^\?/, '');
    const query = extractQuery(queryString);
    const token = query.token || query.t;

    redeem({ token });
  }, [redeem]);

  useEffect(() => {
    if (!siteId || showResendToken) return;

    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    setGaInit(true);
  }, [siteId, showResendToken]);

  useEffect(() => {
    if (!gaInit) return;

    if (siteOptions.gaTID) {
      ReactGA.addTrackers([
        {
          trackingId: siteOptions.gaTID,
          gaOptions: {
            name: 'clientTracker',
          },
        },
      ]);
    }

    ReactGA.pageview(window.location.pathname + window.location.search, [
      'clientTracker',
    ]);
  }, [siteOptions.gaTID, gaInit]);

  function renderContents() {
    if (showTwoFactor) {
      return <TwoFactor />;
    }

    if (showResendToken) {
      return <Resend />;
    }

    if (siteId && !showResendToken) {
      return (
        <>
          <Site />
          {messagingAccess === 0 ? null : <Chat />}
        </>
      );
    }

    return null;
  }

  return (
    <IconSettings iconPath={process.env.NODE_ENV === 'development' ? '/slds/assets/icons' : '/slds/icons'}>
      <Toasty />
      {isLoading && !isInitalized ? <Spinner /> : null}

      {renderContents()}
    </IconSettings>
  );
};

export default connect(mapState, mapDispatch as any)(App);
