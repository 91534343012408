import React from 'react';
import Icon from '@salesforce/design-system-react/lib/components/icon';

import styles from './Checklist.module.scss';

interface Props {
  checked?: boolean;
}

function CheckMark({ checked }: Props) {
  return (
    <div className={styles.checkboxContainer}>
      {checked ? (
        <Icon
          assistiveText={{ label: 'Checkbox' }}
          category="action"
          name="check"
          size="x-small"
        />
      ) : (
        <div className={styles.unchecked} />
      )}
    </div>
  );
}

export default CheckMark;
