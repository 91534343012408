import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import range from 'lodash/range';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { pdfjs } from 'react-pdf';

import { Plugin } from './types';
import * as PluginsTypes from 'plugins';
import { HandleUploadObject } from 'plugins/FileUpload/types';
import SFieldState from 'states/SFieldState';

import { Dispatch, RootState } from 'store';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

interface DispatchProps {
  fileUpload?: (a: any) => any;
  fileDelete?: (a: any) => any;
  actionClick?: (a: any) => any;
  sendESign?: (a: any) => any;
}

interface StateProps {
  userRole?: number;
  deleteAccess?: number;
  supportedFileTypes?: string[];
  orgId?: string;
  roomId?: string;
  sessionId?: string;
  user?: any;
  sobjectType?: string;
  masterRecordId?: string;
  esign?: any;
  memberId: string;
}

interface PluginsProps {
  plugins: Plugin[];
  columns: number;
  pageId: string;
  page: any;
  setPage: (pageName: string) => void;
}

const mapState = (state: RootState): any => ({
  userRole: state.app.role,
  deleteAccess: state.site.itemDeleteAccess,
  supportedFileTypes: state.site.supportedFileTypes,
  orgId: state.app.orgId,
  roomId: state.app.roomId,
  sessionId: state.app.sessionId,
  user: state.app.user,
  sobjectType: state.site.sobjectType,
  esign: state.site.esign,
  masterRecordId: state.app.room.masterRecordId,
  memberId: state.app.memberId,
});

const mapDispatch = (dispatch: Dispatch): any => ({
  actionClick: dispatch.site.actionClick,
  fileUpload: dispatch.site.fileUpload,
  fileDelete: dispatch.site.fileDelete,
  sendESign: dispatch.site.sendESign,
});

function mapToColumns(plugins: Plugin[]) {
  return plugins.reduce((stack: any, item: Plugin): any => {
    const column = item.layoutColumn;
    return {
      ...stack,
      [column]: stack[column] ? [...stack[column], item] : [item],
    };
  }, {} as any);
}

function Plugins(props: PluginsProps & StateProps & DispatchProps) {
  const handleUpload = useCallback(
    ({ data, id }: HandleUploadObject) => {
      return new Promise(async (resolve) => {
        const { fileUpload, pageId } = props;
        if (fileUpload) {
          await fileUpload({
            id,
            pageId,
            data,
          });

          resolve();
        } else {
          resolve();
        }
      });
    },
    [props.pageId]
  );

  const handleDocumentAction = useCallback(
    ({ type, id, url, data }: any): void => {
      switch (type) {
        // @TODO: Remove this. This isn't being used anymore (need to test first before removal)
        case 'download':
          window.open(url, 'idlog-download');
          break;

        case 'delete':
          if (typeof props.fileDelete === 'function') {
            return props.fileDelete(id);
          }
          break;

        case 'generic-action':
          if (typeof props.actionClick === 'function') {
            return props.actionClick({
              id,
              data: { ...data, pageId: props.pageId },
            });
          }
          break;

        case 'esign':
          if (typeof props.sendESign === 'function') {
            return props.sendESign({
              id,
              data,
            });
          }
          break;
      }
    },
    [props.pageId]
  );

  const plugins = mapToColumns(props.plugins);
  const render = range(props.columns).map((col: number) => {
    const column = plugins[col] || [];
    const columnItems = column
      .filter((plugin: Plugin) => !!(PluginsTypes as any)[plugin.type])
      .sort((left: Plugin, right: Plugin) =>
        left.layoutRow > right.layoutRow ? 1 : -1
      );

    return (
      <div
        className={`slds-col slds-large-size_1-of-${props.columns} slds-size_1-of-1`}
        key={col}
      >
        {columnItems.map((plugin: Plugin, index: number) => {
          const Component = (PluginsTypes as any)[plugin.type];

          const uniqueProps: any = {};

          switch (plugin.type) {
            case 'Upload': {
              const { supportedFileTypes } = props;
              uniqueProps.onUpload = handleUpload;
              uniqueProps.supportedFileTypes = supportedFileTypes;
              uniqueProps.multiFileEnabled = plugin.multiFileEnabled;
              break;
            }

            case 'Document': {
              const { userRole, deleteAccess } = props;
              uniqueProps.onActionClick = handleDocumentAction;
              uniqueProps.actions = plugin.actions || [];
              uniqueProps.user = props.user;
              uniqueProps.canDelete =
                userRole !== undefined &&
                deleteAccess !== undefined &&
                userRole >= deleteAccess &&
                deleteAccess !== 0;
              uniqueProps.enableFreeFormSignatures =
                props.esign.enableFreeFormSignatures;
              uniqueProps.enableAdoptedSignatures =
                props.esign.enableAdoptedSignatures;
              uniqueProps.orgId = props.orgId;
              uniqueProps.roomId = props.roomId;
              break;
            }

            case 'Payment': {
              uniqueProps.orgId = props.orgId;
              uniqueProps.roomId = props.roomId;
              uniqueProps.user = props.user;
              uniqueProps.apiKey = plugin.apiKey;
              uniqueProps.memberId = props.memberId;
              break;
            }

            case 'SalesforceField': {
              uniqueProps.pageId = props.pageId;
              break;
            }

            case 'SFieldSubmit': {
              uniqueProps.data = get(props.page, ['actions', 0], {
                displayLabel: 'Submit',
                displayType: 'BUTTON',
                displayColor: '#0070d2',
              });
              uniqueProps.pageId = props.pageId;
              uniqueProps.sobjectType = props.sobjectType;
              uniqueProps.masterRecordId = props.masterRecordId;
              uniqueProps.roomId = props.roomId;
              uniqueProps.orgId = props.orgId;
              break;
            }

            case 'Configurator': {
              uniqueProps.roomId = props.roomId;
              uniqueProps.user = props.user;
              uniqueProps.orgId = props.orgId;
              uniqueProps.memberId = props.memberId;
              break;
            }
          }

          return (
            <Component
              key={`${plugin.name}-${index}`}
              id={`${plugin.id}-${index}`}
              value={plugin.content}
              plugin={omit(plugin, ['contentDocument', 'item'])}
              extraData={{ ...plugin.contentDocument, ...plugin.item }}
              title={plugin.title}
              setPage={props.setPage}
              {...uniqueProps}
            />
          );
        })}
      </div>
    );
  });

  return <SFieldState.Provider>{render}</SFieldState.Provider>;
}

export default connect(mapState, mapDispatch)(Plugins);
