import api, { updateConfig, URL } from 'helpers/api';
import { getCookie, setCookies, cleanCookies } from 'helpers/cookieParser';
import get from 'lodash/get';

import env from 'helpers/env';
import { ToastyManager } from 'containers/Toasty';
import { RedeemParams } from './types';

const ORG_ID = '00D1U0000012g3p';

export default function AppEffects(dispatch: any) {
  return {
    async redeem(params: RedeemParams) {
      try {
        if (process.env.NODE_ENV === 'development') {
          const cachedSession = localStorage.getItem(
            'idlog-rooms-session-cache'
          ) as string;

          if (cachedSession !== env('REACT_APP_SESSION', '')) {
            localStorage.clear();
            localStorage.setItem(
              'idlog-rooms-session-cache',
              env('REACT_APP_SESSION', '')
            );
          }
        }

        const paramToken =
          process.env.NODE_ENV === 'development'
            ? env('REACT_APP_TOKEN', '')
            : params.token;

        const cookieSession =
          process.env.NODE_ENV === 'development'
            ? env('REACT_APP_SESSION', '')
            : getCookie(paramToken);

        const response = cookieSession
          ? await api.post(
              '/auth/sessions',
              {
                sessionId: cookieSession,
              },
              true
            )
          : await api.post(
              '/auth/tokens/redeem',
              {
                token: paramToken,
                origin: 'web',
              },
              true
            );

        const {
          success,
          message,
          sessionId,
          roomId,
          orgId,
          orgid,
          memberId = '',
          user,
        } = response;

        if (success) {
          if (!cookieSession) {
            const expiryDate = new Date();
            expiryDate.setFullYear(expiryDate.getFullYear() + 1);

            setCookies({
              [paramToken]: response.sessionId,
              expires: expiryDate.toUTCString(),
            });

            cleanCookies(25);
          }

          updateConfig({
            headers: { Authorization: sessionId },
            baseURL: `${URL}/orgs/${orgId}`,
          });
          const firstName = get(user, 'firstName');
          const lastName = get(user, 'lastName');
          const memberDisplayName =
            [firstName, lastName].filter((i) => !!i).join(' ') || '';

          dispatch.app.modify({
            sessionId,
            roomId,
            orgId: orgId || orgid || ORG_ID,
            memberId,
            memberDisplayName,
            user,
            token: paramToken,
            role: get(user, 'role', 1),
          });

          if (roomId) {
            dispatch.app.getRoomDetails();
          }
        } else {
          if (response.statusCode !== 401) {
            dispatch.app.update({ key: 'showResendToken', value: true });
          }

          throw { success, message };
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          ToastyManager.emit({
            variant: 'error',
            message:
              'Development prerequisite: Add REACT_APP_TOKEN and REACT_APP_SESSION in your .env file with a valid token and its session ID',
          });
        } else {
          ToastyManager.emit({ variant: 'error', message: error.message });
        }
      }
    },
    async getRoomDetails(_: any, rootState: any) {
      const { roomId } = rootState.app;

      try {
        await dispatch.site.getSite(roomId);

        dispatch.app.update({ key: 'isInitialized', value: true });
      } catch (error) {
        console.error(error);
        ToastyManager.emit({ variant: 'error', message: error.message });
      }
    },
  };
}
