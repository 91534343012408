import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { createPortal } from 'react-dom';

import Content from './Content';
import Form from './Form';

const slideUp = keyframes`
  from {
    transform: translateY(1em);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${slideUp} 1s ease;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  bottom: calc(1rem + 60px);
  display: flex;
  flex-direction: column;
  height: calc(100% - 150px);
  width: 336px;
  position: fixed;
  transform: translateY(0);
  right: 1rem;
  z-index: 1;
  opacity: 1;
`;

export default function ChatBox() {
  return createPortal(
    <Container>
      <Content />
      <Form />
    </Container>,
    document.getElementById('root') || document.body
  );
}
